/** @jsx jsx */

import { HTMLAttributes } from 'react';
import { jsx } from '@balance-web/core';
import { Stack } from '@balance-web/stack';
import { useTheme } from '@balance-web/theme';

import { modules } from './scope';
import { Highlight } from './Highlight';

export const PackageMeta = ({ name }: { name: string }) => {
  let pkgExports = modules[name];
  if (!pkgExports) {
    throw new Error('package exports not found for ' + name);
  }
  let exportKeys = Object.keys(pkgExports);

  // we don't want super long lists of imports
  // ideally we would let people expand and collapse them but this is easier for now
  if (exportKeys.length > 30) {
    exportKeys = exportKeys.slice(0, 28).concat('...');
  }
  return (
    <Stack borderRadius="small" gap="medium" marginY="xxlarge">
      <Section>
        <Label>Install</Label>
        <Value>
          <code>pnpm add {name}</code>
        </Value>
      </Section>
      <Section>
        <Label>Import usage</Label>
        <Value>
          <pre css={{ lineHeight: 1.4 }}>
            <Highlight
              code={
                `import {\n  ` +
                exportKeys.join(',\n  ') +
                `\n} from '${name}';`
              }
              language="javascript"
            />
          </pre>
        </Value>
      </Section>
    </Stack>
  );
};

// Styled Components
// ------------------------------

const mediumUp = '@media (min-width: 780px)';

const Label = (props: HTMLAttributes<HTMLElement>) => {
  const { palette } = useTheme();

  return (
    <div
      css={{
        color: palette.text.muted,

        [mediumUp]: {
          width: 200,
        },
      }}
      {...props}
    />
  );
};
const Value = (props: HTMLAttributes<HTMLElement>) => {
  const { palette, typography } = useTheme();

  return (
    <div
      css={{
        color: palette.text.base,
        flex: 1,

        a: {
          color: palette.text.link,
        },
        code: {
          fontFamily: typography.fontFamily.monospace,
          fontSize: typography.fontSize.small,
        },
      }}
      {...props}
    />
  );
};
const Section = (props: HTMLAttributes<HTMLElement>) => (
  <section
    css={{
      display: 'flex',
      flexDirection: 'column',

      [mediumUp]: {
        flexDirection: 'row',
      },
    }}
    {...props}
  />
);
