/** @jsx jsx */

import { Fragment, ReactNode } from 'react';
import { Link, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Box } from '@balance-web/box';
import { jsx } from '@balance-web/core';
import { Stack } from '@balance-web/stack';
import { useTheme } from '@balance-web/theme';
import { Text } from '@balance-web/text';

import { PackageMetadataFragment } from '../../graphql-types';
import { titleCase } from '../utils/title-case';
import { Mdx } from '../components/Mdx';

import { useIsPathnameActive } from './useIsPathnameActive';
import { Container } from './Container';
import { DocsTitle } from './DocsTitle';
import { PackageMeta } from './PackageMeta';

export const Package = ({
  pkg,
  demoMDX,
  children,
}: {
  pkg: PackageMetadataFragment;
  demoMDX?: any;
  children: ReactNode;
}) => {
  const { palette, spacing } = useTheme();
  let name = titleCase(pkg.name, '-');
  const demoBody = demoMDX ? demoMDX.body : undefined;
  const isDeprecated = pkg.children.reduce(
    (prev, next) => prev || next.context?.deprecated === true,
    false
  );
  const isDiscouraged = pkg.children.reduce(
    (prev, next) => prev || next.context?.discouraged === true,
    false
  );

  return (
    <Fragment>
      <Helmet>
        <title>{`${name} - Balance Design System`} </title>
      </Helmet>
      <Box
        css={{
          borderBottom: `1px solid ${palette.global.border}`,
        }}
      >
        <Container>
          <Stack gap="large">
            <DocsTitle
              isDeprecated={isDeprecated}
              isDiscouraged={isDiscouraged}
            >
              {name}
            </DocsTitle>
            <Stack gap="large" css={{ paddingRight: 260 }}>
              {pkg.description && (
                <Text color="muted" size="xlarge">
                  {pkg.description}
                </Text>
              )}
              {demoBody && <Mdx>{demoBody}</Mdx>}
              <PackageMeta name={pkg.nameWithScope} />
            </Stack>
            <ul css={{ display: 'flex' }}>
              {pkg.children.map((tab, idx) => {
                return (
                  <Tab to={tab.path} key={tab.path}>
                    {tab.context!.subSection}
                  </Tab>
                );
              })}
            </ul>
          </Stack>
        </Container>
      </Box>
      <Box
        id="doc-content"
        css={{ flex: 1, minWidth: 0, paddingTop: spacing.xxlarge }}
      >
        <Container>{children}</Container>
      </Box>
    </Fragment>
  );
};

export const fragment = graphql`
  fragment PackageMetadata on Package {
    name(scope: false)
    nameWithScope: name
    children {
      ... on SitePage {
        path
        context {
          subSection
          deprecated
          discouraged
        }
      }
    }
    description
    version
  }
`;

const Tab = (props: { to: string; children: ReactNode }) => {
  const { palette, radii, spacing } = useTheme();

  const isActive = useIsPathnameActive(props.to, false);

  return (
    <li role="presentation">
      <Link
        {...props}
        css={{
          border: `1px solid transparent`,
          borderTopLeftRadius: radii.xsmall,
          borderTopRightRadius: radii.xsmall,
          bottom: -1,
          boxSizing: 'border-box',
          color: palette.text.muted,
          cursor: 'pointer',
          display: 'block',
          fontWeight: 500,
          outline: 0,
          padding: `${spacing.medium} ${spacing.large}`,
          position: 'relative',
          textAlign: 'left',
          width: '100%',
          textDecoration: 'none',

          ':hover': {
            color: palette.text.base,
          },
          ...(isActive && {
            background: palette.background.base,
            border: `1px solid ${palette.global.border}`,
            borderBottomColor: 'transparent',
            color: palette.text.base,
          }),
        }}
        activeClassName="active"
      />
    </li>
  );
};
