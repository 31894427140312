/** @jsx jsx */

import { graphql } from 'gatsby';
import { jsx } from '@balance-web/core';

import { PackagePageQuery } from '../../graphql-types';
import { TableOfContents } from '../components/TableOfContents';
import { Mdx } from '../components/Mdx';
import { Package } from '../components/Package';

const PackagePage = (props: { data: PackagePageQuery }) => {
  let pkg = props.data.package!;
  let mdx = pkg.mdx!;

  return (
    <Package pkg={pkg} demoMDX={pkg.demo}>
      <div css={{ display: 'flex' }}>
        <main css={{ flex: 1, minWidth: 0, paddingBottom: 48 }}>
          <Mdx>{mdx.body}</Mdx>
        </main>
        <TableOfContents toc={mdx.tableOfContents} />
      </div>
    </Package>
  );
};

export const pageQuery = graphql`
  query PackagePage($id: String!, $filename: String!) {
    package(id: { eq: $id }) {
      ...PackageMetadata
      mdx(path: $filename) {
        body
        tableOfContents
      }
      demo: mdx(path: "DEMO.md") {
        body
        tableOfContents
      }
    }
  }
`;

export default PackagePage;
